import { getAccessToken, isLoggedIn, login } from '../utils/AuthService';
import { COMMANDS, PLATFORMS, TW } from './Constants';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import CustomNotification from './CustomNotification.js';
import React from 'react';

const WSS_URI = getWssUri();

let echoinc = 0;

let pingTimeout;
let pingInterval;
const progressToastIds = {};

function getWssUri() {
  if (process.env.NODE_ENV === 'development') {
    return 'ws://localhost:4000/ui';
  } else {
    return `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/ui`;
  }
}

function checkErrors(res) {
  if (res.ok) {
    return true;
  } else {
    res
      .text()
      .then(text => {
        let msg;
        try {
          const json = JSON.parse(text);
          if (json.message) {
            msg = json.message;
          } else if (json.error) {
            msg = json.error.message || json.error;
          } else {
            msg = text;
          }
        } catch {
          msg = text;
        }
        if (msg) Sentry.captureMessage(msg);
        toast.error(msg);
      })
      .catch(err => {
        console.error(err.stack || err);
        toast.error(`Unknown http error occurred: ${res.url} ${res.status}`);
        Sentry.captureException(err);
      });
    return false;
  }
}

function get(url) {
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getFacebookAccounts() {
  return get('/api/fb/account');
}

export function getTaboolaAccounts() {
  return get('/api/tb/network');
}

export function getBaiduAccounts() {
  return get('/api/bd/account');
}

export function getPinterestAccounts() {
  const url = `/api/pnAccounts`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getRevcontentAccounts() {
  return get('/api/rc/account');
}

export function getCreators() {
  return get('/api/creator');
}

export function getGoogleAdsAccounts() {
  return get('/api/ga/account');
}

export function getGoogleAdsCampaigns(account) {
  return get(`/api/ga/campaigns?account=${account}`);
}

export function getBaiduCampaigns(account) {
  return get(`/api/bd/campaigns?account=${account}`);
}

export function getOutbrainMetadata() {
  return get(`/api/ob/metadata`);
}

export function getOutbrainAccounts() {
  return get(`/api/ob/account`);
}

export function getOutbrainCampaigns(account) {
  return get(`/api/ob/campaign?account=${account}`);
}

export function getZemantaMetadata() {
  return get(`/api/zm/metadata`);
}

export function getZemantaAccounts() {
  return get(`/api/zm/account`);
}

export function getZemantaCampaigns(account) {
  return get(`/api/zm/campaign?account=${account}`);
}

export function getZemantaPublishers(account) {
  return get(`/api/zm/publisher?account=${account}`);
}

export function getGoogleAdsCampaignByName(campaign) {
  const url = `/api/ga/campaign?id=${campaign}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getPinterestBoards(acc) {
  console.log(acc);
  const url = `/api/pinterest/boards?account=${acc}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getBaiduCampaign(acc, campName) {
  const url = `/api/bd/campaign?acc=${acc}&campName=${campName}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function loadTaboolaBulk(ws, request) {
  ws.send(
    JSON.stringify({
      cmd: 'def/tb/campaign/load/bulk',
      ...request
    })
  );
}

export function loadTaboolaCampaign(ws, request) {
  ws.send(
    JSON.stringify({
      cmd: 'def/tb/campaign/load',
      ...request
    })
  );
}

export function loadOutbrainCampaign(ws, request) {
  ws.send(
    JSON.stringify({
      cmd: 'def/ob/campaign/load',
      ...request
    })
  );
}

export function loadOutbrainBulk(ws, request) {
  ws.send(
    JSON.stringify({
      cmd: 'def/ob/campaign/load/bulk',
      ...request
    })
  );
}

export function rejectReview(snapshotId, reason) {
  const url = `/api/rejectReview`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      snapshotId,
      reason
    })
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }

    return res.json().catch(jsonErrorHandler(res));
  });
}

function wordWrap(s) {
  const lines = [];
  for (let i = 0; i < s.length; i += 34) {
    lines.push(s.slice(i, i + 34));
  }
  return lines.join('\n');
}

function jsonErrorHandler(res) {
  return err => {
    console.error(err.stack || err);
    Sentry.captureException(err);
    if (res.status === '413') {
      toast.error('File is too big.');
    } else {
      toast.error(err.stack);
      toast.error(wordWrap(`Can't parse json in ${res.url}`) + `\nstatus: ${res.status}`);
    }
    return [];
  };
}

export function getTaboolaSubAccounts() {
  return fetch(`/api/tb/account`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getLink(adName) {
  const url = 'api/link?adName=' + adName;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getFacebookCampaigns(account) {
  return get(`/api/fb/campaign?account=${account}`);
}

export function getRevcontentCampaigns(account) {
  return get(`/api/rc/campaign?account=${account}`);
}

export function getTaboolaCampaign(acc, subAcc) {
  const url = `api/tb/campaign?account=${acc}/${subAcc}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(err => {
      console.error(err.stack || err);
      toast.error(`Can't parse json in ${res.url} ${res.status}`);
      Sentry.captureException(err);
      return [];
    });
  });
}
export function getGoogleAdsConversions(acc) {
  let url = '/api/ga/conversion?account=' + acc;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getRevcontentConversions(account) {
  return get(`/api/rc/conversion?account=${account}`);
}

export function getFbConversionsAndPixels(acc, cached) {
  const url = `/api/fb/conversion?account=${acc}${cached ? '&cached=1' : ''}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getFbBmConversionFilterList(bm) {
  const url = `/api/fb/bm/conversionFilter?id=${bm}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getVideoCreatives(creativeId) {
  const url = `/api/video_creatives?creative_id=${creativeId}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getArticlesSubCategories() {
  const url = '/api/articlesSubCategories';
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getArticleList() {
  const url = '/api/article';
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getBlacklistedCreativesList() {
  const url = '/api/blcreatives';
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getObInterests() {
  const url = '/api/interestsOb';
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getPresets(platform) {
  const url = '/api/getPresets?platform=' + platform;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function retrievePreset(id, platform) {
  const url = `/api/getPreset?id=${id}&platform=${platform}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function createPreset(name, email, preset, platform) {
  const url = `/api/createPreset?name=${name}&email=${email}&platform=${platform}`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: preset
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function updatePreset(id, name, platform) {
  const url = `/api/updatePreset?id=${id}&name=${name}&platform=${platform}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function deletePreset(id, platform) {
  const url = `/api/deletePreset?id=${id}&platform=${platform}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getServiceMessage() {
  const url = `/api/serviceMessage`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getSingleInsight(id) {
  const url = `/api/insight/single?id=${id}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getWidgetIdFromLink(link, campaignName) {
  const url = `/api/insight/widgetIdFromLink`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ link, campaignName })
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getCreatives(id) {
  const url = `/api/insight/byCreative?id=${id}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getCreativesReview() {
  const url = `/api/getCreatives`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getCreativesByPrefix(prefix) {
  const url = `/api/getCreativesByPrefix?prefix=${prefix}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getCreativesByArticle(prefix, article) {
  const url = `/api/getCreativesByArticle?prefix=${prefix}&article=${article}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getPnConversions(acc) {
  const url = `/api/pnConversions?acc=${acc}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getCreativesByDate(prefix, startDate, endDate) {
  const url = `/api/getCreativesByDate?prefix=${prefix}&startDate=${startDate}&endDate=${endDate}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getFilteredCreatives(prefix, article, text, title, status, comment, reviewer, startTime, endTime) {
  const url = `/api/getFilteredCreatives?prefix=${prefix}&article=${article}&text=${text}&title=${title}&status=${status}&comment=${comment}&reviewer=${reviewer}&startTime=${startTime}&endTime=${endTime}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function addCreativesReview(creatives) {
  const url = `/api/addCreatives`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creatives)
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getNegativeWordsByWordType(type) {
  const url = `/api/negword?type=${type}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getPrefixList() {
  const url = `/api/getPrefixList`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}
export function getB2BPrefixes() {
  const url = `/api/b2bPrefixes`;
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export async function getPinterestCampaigns(acc) {
  const url = `/api/pinterest/campaigns?account=${acc}`;
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
  if (!checkErrors(res)) {
    return [];
  }
  return res.json().catch(jsonErrorHandler(res));
}

export function updateCreativesReview(creatives) {
  const url = `/api/updateCreatives`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(creatives)
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export async function getFbLibraryAds(id) {
  const url = `/api/fb/library?id=${id}`;
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
  if (!checkErrors(res)) {
    return [];
  }
  return res.json().catch(jsonErrorHandler(res));
}

export async function getAdName(name) {
  const url = `/api/ad/name/load?id=${name}`;
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  });
  if (!checkErrors(res)) {
    return [];
  }
  return res.json().catch(jsonErrorHandler(res));
}

export function reinitializeServer() {
  const url = '/api/reinitializeServer';
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getSnapshot(snapshotId) {
  const url = `/api/snapshot?id=${snapshotId}`;

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }

    return res.json().catch(jsonErrorHandler(res));
  });
}

export function createPinterestCampaign(preparedOptions) {
  const url = `/api/createPnCampaign`;

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(preparedOptions)
  })
    .then(async res => {
      const data = await res.json();
      if (res.ok) {
        return data;
      } else {
        return {
          success: false,
          error: data.error?.message || 'Failed to create campaign',
          failureStage: data.failureStage,
          cleanupStatus: data.cleanupStatus,
          apiError: data.apiError
        };
      }
    })
    .catch(error => {
      console.error('Error creating campaign:', error);
      return {
        success: false,
        error: error.message || 'Unexpected error occurred'
      };
    });
}

export function updatePinterestCampaign(preparedOptions) {
  const url = `/api/updatePinterestCampaign`;

  const data = {
    preparedOptions: preparedOptions
  };
  console.log(data);
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function createPinterestAdGroup(preparedOptions) {
  const url = `/api/createPinterestAdGroup`;

  const data = {
    preparedOptions: preparedOptions
  };
  console.log(data);
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function createPinterestPins(preparedOptions) {
  const url = `/api/createPinterestPins`;

  const data = {
    preparedOptions: preparedOptions
  };
  console.log(data);
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function createPinterestAds(preparedOptions) {
  const url = `/api/createPinterestAds`;

  const data = {
    preparedOptions: preparedOptions
  };
  console.log(data);
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function upsertSnapshot(ws, sessionIndex, destAccount, platform, options, snapshot) {
  const payload = {
    destinationAccount: destAccount,
    session: sessionIndex,
    platform,
    options,
    snapshot
  };
  ws.send(
    JSON.stringify({
      cmd: COMMANDS.UPSERT_SNAPSHOT,
      data: payload
    })
  );
}

export function createCampaign(ws, session, destinationAccount, platform, options, snapshot, devSkipCreate) {
  platform = platform === PLATFORMS.FACEBOOK_BULK || platform === PLATFORMS.FACEBOOK2 ? PLATFORMS.FACEBOOK : platform;
  const request = {
    cmd: COMMANDS.CREATE_CAMPAIGNS,
    platform,
    session,
    data: {
      destinationAccount,
      options,
      snapshot
    }
  };
  if (devSkipCreate) {
    request.devSkipCreate = devSkipCreate;
  }
  ws.send(JSON.stringify(request));
  return request;
}

export function updateCamp(ws, session, platform, options) {
  const request = {
    cmd: COMMANDS.UPDATE_CAMPAIGNS,
    platform,
    session,
    data: {
      options
    }
  };
  ws.send(JSON.stringify(request));
  return request;
}

export function duplicateCampaign(
  ws,
  session,
  destinationAccount,
  platform,
  options,
  userOptions,
  titles,
  texts,
  images,
  snapshot,
  devSkipCreate
) {
  const request = {
    cmd: COMMANDS.DUPLICATE_CAMPAIGN,
    session,
    platform,
    data: {
      destinationAccount,
      options,
      userOptions,
      titles,
      texts,
      images,
      snapshot
    }
  };
  if (devSkipCreate) {
    request.devSkipCreate = devSkipCreate;
  }
  ws.send(JSON.stringify(request));
  return request;
}

export function startRead(ws, sessionIndex, platform, options) {
  const payload = {
    cmd: COMMANDS.START_READ,
    data: {
      platform: platform,
      session: sessionIndex
    }
  };
  switch (platform) {
    case PLATFORMS.BAIDU:
      payload.data.account = options.account;
      payload.data.subAccount = options.subAccount;
      payload.data.campaign = options.campaign;
      ws.send(JSON.stringify(payload));
      break;
    // case PLATFORMS.TABOOLA:
    //   payload.data.account = options.account;
    //   payload.data.subAccount = options.subAccount;
    //   payload.data.campaign = options.campaign;
    //   payload.data.campaignName = options.campaignName;
    //   ws.send(JSON.stringify(payload));
    //   break;
    // case PLATFORMS.TABOOLA_BULK:
    //   payload.data.account = options.account;
    //   payload.data.campaignName = options.campaigns;
    //   ws.send(JSON.stringify(payload));
    //   break;
    case PLATFORMS.REVCONTENT:
      payload.data.account = options.account;
      payload.data.campaign = options.campaign;
      ws.send(JSON.stringify(payload));
      break;
    // case PLATFORMS.OUTBRAIN:
    //   payload.data.account = options.account;
    //   payload.data.campaign = options.campaign;
    //   ws.send(JSON.stringify(payload));
    //   break;
    case PLATFORMS.PINTEREST:
      payload.data.account = options.account;
      payload.data.campaign = options.campaign;
      ws.send(JSON.stringify(payload));
      break;
    default:
      throw new Error('Unknown platform ' + platform);
  }
}

export function fetchBulkCampaigns(ws, sessionIndex, campaignName, platform, accountId) {
  const payload = {
    cmd: COMMANDS.FETCH_BULK_CAMPAIGNS,
    platform: platform,
    session: sessionIndex,
    data: {}
  };
  payload.data.account = accountId;
  payload.data.names = typeof campaignName === 'string' ? [campaignName] : campaignName;
  ws.send(JSON.stringify(payload));
}

export function fetchCampaigns(ws, sessionIndex, platform, campaignData, accountId) {
  const payload = {
    cmd: COMMANDS.FETCH_CAMPAIGNS,
    platform: platform,
    session: sessionIndex,
    data: {}
  };

  switch (platform) {
    case PLATFORMS.FACEBOOK:
      payload.data.account = accountId;
      payload.data.campaignId = campaignData.id;
      payload.data.campaignName = campaignData.name;
      ws.send(JSON.stringify(payload));
      break;
    default:
      throw new Error('Unknown platform ' + platform);
  }
}

export function fetchCampaignsStructure(ws, sessionIndex, platform, campaignId, accountId) {
  const payload = {
    cmd: COMMANDS.FETCH_CAMPAIGNS_STRUCTURE,
    platform: platform,
    session: sessionIndex,
    data: {
      ids: [campaignId]
    }
  };
  switch (platform) {
    case PLATFORMS.FACEBOOK:
      payload.data.account = accountId;
      ws.send(JSON.stringify(payload));
      break;
    default:
      throw new Error('Unknown platform ' + platform);
  }
}

export function copyCampaign(ws, session, platform, data, devSkipCreate) {
  if (platform !== PLATFORMS.FACEBOOK) {
    throw new Error(`Unknown platform ${platform}`);
  }
  const request = {
    cmd: COMMANDS.COPY_CAMPAIGN,
    platform,
    session,
    data
  };
  if (devSkipCreate) {
    request.devSkipCreate = devSkipCreate;
  }
  ws.send(JSON.stringify(request));
  return request;
}

function uploadResultToUrlArray(json) {
  const result = [];
  for (const i in json) {
    const url = json[i].data?.url;
    url && result.push(url);
  }
  return result;
}

function uploadResultToArray(json) {
  const result = [];
  for (const i in json) {
    json[i].data?.url && result.push(json[i]);
  }
  return result;
}

export function storeFiles(formData, options) {
  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'x-spm-on-before': 'scale1'
  };
  if (options) {
    if (options.minWidth) {
      headers['X-Min-Width'] = options.minWidth;
    }
    if (options.minHeight) {
      headers['X-Min-Height'] = options.minHeight;
    }
    if (options.cropWidth) {
      headers['X-Min-Width'] = options.cropWidth;
    }
    if (options.cropHeight) {
      headers['X-Min-Height'] = options.cropHeight;
    }
    if (options.noDownscale) {
      headers['X-No-Downscale'] = 'yes';
    }
  }
  return fetch('api/upload/image', {
    method: 'POST',
    headers: headers,
    body: formData
  }).then(res =>
    res
      .json()
      .then(uploadResultToUrlArray)
      .catch(jsonErrorHandler(res))
  );
}
export function storeVideos(formData, options) {
  const headers = {
    Authorization: `Bearer ${getAccessToken()}`
  };
  if (options) {
    if (options.minWidth) {
      headers['X-Min-Width'] = options.minWidth;
    }
    if (options.minHeight) {
      headers['X-Min-Height'] = options.minHeight;
    }
    if (options.noDownscale) {
      headers['X-No-Downscale'] = 'yes';
    }
  }

  return fetch('api/upload/video', {
    method: 'POST',
    headers: headers,
    body: formData
  }).then(res =>
    res
      .json()
      .then(uploadResultToUrlArray)
      .catch(jsonErrorHandler(res))
  );
}

export function storeFilesUploads(formData, options) {
  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'x-spm-on-before': 'scale1',
    'x-spm-on-after': 'text1'
  };
  if (options) {
    if (options.minWidth) {
      headers['X-Min-Width'] = options.minWidth;
    }
    if (options.minHeight) {
      headers['X-Min-Height'] = options.minHeight;
    }
    if (options.cropWidth) {
      headers['X-Min-Width'] = options.cropWidth;
    }
    if (options.cropHeight) {
      headers['X-Min-Height'] = options.cropHeight;
    }
    if (options.noDownscale) {
      headers['X-No-Downscale'] = 'yes';
    }
  }
  return fetch('api/upload/image', {
    method: 'POST',
    headers: headers,
    body: formData
  }).then(res =>
    res
      .json()
      .then(uploadResultToArray)
      .catch(jsonErrorHandler(res))
  );
}

export function collage(data) {
  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'Content-Type': 'application/json'
  };
  return fetch('api/collage', {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  }).then(res => {
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function storeFilesTwitter(formData, options) {
  const headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    'x-spm-on-before': 'crop1'
  };
  if (options) {
    if (options.cropWidth) {
      headers['X-Min-Width'] = options.cropWidth;
    }
    if (options.cropHeight) {
      headers['X-Min-Height'] = options.cropHeight;
    }
  }
  return fetch('api/upload/image', {
    method: 'POST',
    headers: headers,
    body: formData
  }).then(res =>
    res
      .json()
      .then(uploadResultToUrlArray)
      .catch(jsonErrorHandler(res))
  );
}

export function initSession(callback, setWsCallback, sessionIndex) {
  let ws;

  const isConnectionOpen = () => {
    return ws && ws.readyState === WebSocket.OPEN;
  };

  const heartbeat = () => {
    clearTimeout(pingTimeout);
    pingTimeout = setTimeout(() => {
      console.log('WS connection ping timeout, something is wrong');
    }, 60000);
  };

  const reconnect = () => {
    ws = new WebSocket(WSS_URI, getAccessToken());
    initWebSocketEvents();
  };

  const initWebSocketEvents = () => {
    console.log('init WS events');
    ws.sessionIndex = sessionIndex;
    ws.onopen = () => {
      setWsCallback(ws);

      if (sessionIndex) {
        if (isConnectionOpen()) {
          ws.send(
            JSON.stringify({
              cmd: COMMANDS.RECONNECT,
              authorization: getAccessToken(),
              data: { session: sessionIndex }
            })
          );
        }
      } else {
        if (isConnectionOpen()) {
          ws.send(
            JSON.stringify({
              cmd: COMMANDS.INIT_SESSION,
              authorization: getAccessToken(),
              data: {}
            })
          );
        }
      }

      pingInterval = setInterval(() => {
        if (isConnectionOpen()) {
          ws.send('{"cmd": "echo", "data": "check"}');
          heartbeat();
        }
      }, 30000);
    };

    ws.onerror = err => {
      console.error(`WebSocket error: ${err.message}`);
      Sentry.captureException(err);
      clearTimeout(pingTimeout);
      clearInterval(pingInterval);
      callback(err);
    };

    ws.onmessage = message => {
      let parsed = JSON.parse(message.data);
      if (parsed.cmd === 'echo' && parsed.data && parsed.data === 'check') {
        heartbeat();
      } else {
        if (parsed.cmd === COMMANDS.INIT_SESSION) {
          ws.sessionIndex = parsed.data;
        }
        if (parsed.error || parsed.result) {
          return callback(parsed, parsed.cmd);
        }
        callback(parsed.data || parsed, parsed.cmd);
      }
    };

    ws.onclose = () => {
      clearInterval(pingInterval);
      clearTimeout(pingTimeout);
      console.log('WebSocket connection lost, reconnecting', ws.sessionIndex);
      setWsCallback(null);
      // Happens when jwt token expires but page wasn't reloaded
      if (!isLoggedIn()) {
        return login();
      }
      setTimeout(() => {
        reconnect();
      }, 5000);
    };
  };

  reconnect();
}

// Init a new session using existing Websocket connection
export function initNewSession(ws, platform, oldSession) {
  ws.send(
    JSON.stringify({
      cmd: COMMANDS.INIT_SESSION,
      data: {
        platform: platform,
        oldSession: oldSession
      }
    })
  );
}

function wsCall(ws, cmd, request) {
  request.cmd = cmd;
  ws.send(JSON.stringify(request));
  return request;
}

function wsCallTrace(ws, cmd, devSkipCreate, request) {
  request.echo = ++echoinc;
  if (devSkipCreate) {
    request.devSkipCreate = devSkipCreate;
  }
  return wsCall(ws, cmd, request);
}

const wsCampaignCreatePlatform = {
  [PLATFORMS.GOOGLE_ADS]: 'ga',
  [PLATFORMS.TABOOLA]: 'tb',
  [PLATFORMS.OUTBRAIN]: 'ob',
  [PLATFORMS.ZEMANTA]: 'zm'
};

export function wsCampaignLoad(ws, platform, request) {
  return wsCall(ws, `def/${wsCampaignCreatePlatform[platform]}/campaign/load`, request);
}

export function wsCampaignLoadBulk(ws, platform, request) {
  return wsCall(ws, `def/${wsCampaignCreatePlatform[platform]}/campaign/load/bulk`, request);
}

export function wsCampaignCreate(ws, platform, devSkipCreate, request) {
  return wsCallTrace(ws, `trace/${wsCampaignCreatePlatform[platform]}/campaign/create`, devSkipCreate, request);
}

export function clearCache() {
  const url = 'api/clearcache';
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getSuggestedBids(platform) {
  const url = `api/suggestedBid?platform=${platform}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getTwitterAccounts() {
  return twitterWsImpl(
    {
      cmd: 'get_accounts',
      data: {}
    },
    message => {
      return new Promise(resolve => {
        let parsed = JSON.parse(message.data);
        resolve(parsed.data);
      });
    }
  );
}

export function getTwitterCampaigns(accountId) {
  return twitterWsImpl(
    {
      cmd: 'get_campaigns',
      data: {
        account_id: accountId
      }
    },
    message => {
      return new Promise(resolve => {
        let parsed = JSON.parse(message.data);
        resolve(parsed.data);
      });
    }
  );
}

export function getTwitterConversions(accountId) {
  return twitterWsImpl(
    {
      cmd: 'get_account_conversions',
      data: {
        account_id: accountId
      }
    },
    message => {
      return new Promise(resolve => {
        let parsed = JSON.parse(message.data);
        resolve(parsed.data);
      });
    }
  );
}

export function getTwitterFundingSources(accountId) {
  return twitterWsImpl(
    {
      cmd: 'get_account_founding_source',
      data: {
        account_id: accountId
      }
    },
    message => {
      return new Promise(resolve => {
        let parsed = JSON.parse(message.data);
        resolve(parsed.data);
      });
    }
  );
}

export function readTwitterCampaign(accountId, campaignId) {
  return twitterWsImpl(
    {
      cmd: 'read_campaign',
      data: {
        account_id: accountId,
        campaign_id: campaignId
      }
    },
    message => {
      return new Promise(resolve => {
        let parsed = JSON.parse(message.data);
        resolve(parsed.data);
      });
    }
  );
}

export function fetchTwitterBulkCampaigns(names) {
  return twitterWsImpl(
    {
      cmd: 'read_bulk_campaigns',
      data: {
        names: names
      }
    },
    message => {
      return new Promise(resolve => {
        let parsed = JSON.parse(message.data);
        resolve(parsed.data);
      });
    }
  );
}

export function getTwitterAccountByWidgetAndPrefix(widget_id, prefix) {
  return twitterWsImpl(
    {
      cmd: 'autoselect_account',
      data: {
        widget_id: widget_id,
        prefix: prefix
      }
    },
    message => {
      return new Promise((resolve, reject) => {
        let parsed = JSON.parse(message.data);
        if (parsed.error) {
          toast.error(parsed.error.toString());
          reject(parsed.error.toString());
        } else {
          resolve(parsed.data);
        }
      });
    }
  );
}

export function fetchTwitterAccountsData() {
  return twitterWsImpl(
    {
      cmd: 'get_accounts_data'
    },
    message => {
      return new Promise(resolve => {
        let parsed = JSON.parse(message.data);
        resolve(parsed.data);
      });
    }
  );
}

export function createTwitterCampaign(accountId, options, session, isBulk) {
  return twitterWsImpl(
    {
      cmd: 'create_campaign',
      data: {
        account_id: accountId,
        options: options,
        session: session,
        isBulk: isBulk
      }
    },
    message => {
      return new Promise((resolve, reject) => {
        let parsed = JSON.parse(message.data);
        if (parsed.error) {
          // TODO think of a way to refactor error handling
          toast.error(parsed.error.toString());
          reject(parsed.error.toString());
        } else if (parsed.cmd === COMMANDS.PROGRESS) {
          if (parsed.data.index === 0) {
            const toastId = toast(parsed.data.shortMessage, {
              autoClose: false,
              closeOnClick: false
            });
            progressToastIds[parsed.data.session] = toastId;
          }
          const newToastObject = {
            render: <CustomNotification shortText={parsed.data.shortMessage} longText={parsed.data.message} />,
            closeOnClick: false
          };
          if (parsed.data.end) {
            newToastObject.type = toast.TYPE.SUCCESS;
            if (parsed.data.error.length) {
              console.log('Creation errors', parsed.data.error);
              toast.warn(parsed.data.error.toString(), {
                autoClose: false,
                closeOnClick: true,
                type: toast.TYPE.WARNING
              });
            }
          } else {
            newToastObject.type = toast.TYPE.WARNING;
          }
          toast.update(progressToastIds[parsed.data.session], newToastObject);
        } else {
          console.log(parsed.data);
          resolve(parsed.data);
        }
      });
    }
  );
}

function twitterWsImpl(command, messageHandler) {
  return new Promise((resolve, reject) => {
    let host;
    if (window.location.origin.includes('localhost')) {
      host = 'ws://localhost:5005/';
      // host = TW.PROD_SERVER;
      // host = TW.STAGING_SERVER;
    } else if (window.location.origin.includes('staging')) {
      host = TW.STAGING_SERVER;
    } else {
      host = TW.PROD_SERVER;
    }
    console.log('host ', host);
    const ws = new WebSocket(host);
    const PING_INTERVAL = 30000;
    let keepPinging = true;
    const ping = () => {
      if (ws.readyState === 1) {
        // ws.send('{"cmd":"ping"}'); // This goes to shinez-twitter-server, not to shinez-fb-tools-server
        if (keepPinging) {
          setTimeout(ping, PING_INTERVAL);
        }
      } else {
        console.log('WS is not in a ready state');
      }
    };
    ws.onopen = () => {
      ws.send(JSON.stringify(command));
      if (keepPinging) {
        setTimeout(ping, PING_INTERVAL);
      }
    };
    ws.onerror = err => {
      console.log('ws error: ' + JSON.stringify(err));
      toast.error(err.toString());
      Sentry.captureException(err);
      keepPinging = false;
      reject(err);
    };
    // Happens when jwt token expires but page wasn't reloaded
    ws.onclose = () => {
      if (!isLoggedIn()) return login();
    };
    ws.onmessage = message => {
      keepPinging = false;
      return messageHandler(message)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          Sentry.captureException(err);
          reject(err);
        })
        .finally(() => {
          ws.close();
        });
    };
  });
}

export function getTaboolaBrowsers(accountId) {
  return fetch(`api/taboola/browsers?account=${accountId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getTaboolaConversionRules(accountId) {
  return fetch(`/api/tb/conversionRule?token=${accountId}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getTaboolaCustomAudiences(accountId) {
  //omg-sc old Taboola account
  return fetch(`api/tb/customAudience?account=${accountId}/omg-sc`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function setDefaultGmCampaignBidsConfig(campaignData) {
  return fetch('api/updateGmDefaultBids', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(campaignData)
  }).then(res => {
    if (!checkErrors(res)) {
      return [];
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getSitesPerPlatform() {
  return fetch('api/sites_per_platform', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => {
    if (!checkErrors(res)) {
      return {};
    }
    return res.json().catch(jsonErrorHandler(res));
  });
}

export function getArticleData(site, widget_id) {
  return fetch(`api/article_data?site=${site}&widget_id=${widget_id}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/javascript'
    }
  }).then(res => {
    if (!res) {
      return [];
    }
    return res.text().catch(jsonErrorHandler(res));
  });
}

export function getArchive(archive) {
  return fetch(`api/archive/${archive}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => res.json().catch(jsonErrorHandler(res)));
}

export function sendLoggedUser(user) {
  return fetch(`/api/logged_user?user=${user}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`
    }
  }).then(res => res.json().catch(jsonErrorHandler(res)));
}
