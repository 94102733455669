import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import ZemantaBids from './ZemantaBids';
import DatePicker from 'react-datepicker';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import { confirmAlert } from 'react-confirm-alert';
import { Button, FormControlLabel, Grid, Switch, Tooltip, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';

import ZemantaCreativeGroupBuilder from './ZemantaCreativeGroupBuilder';
import { getZemantaMetadata, storeFiles } from '../Api';
import { getPlatformSites } from '../Sites';
import { GLOBAL, PLATFORMS, TASKS, ZM } from '../Constants';
import WidgetSelector from '../WidgetSelector';
import { parseCampaignName } from '../NameParser';
import { toast } from 'react-toastify';
import CampaignsNote from '../CampaignsNote';
import {
  displayWarnings,
  getImageDimensions,
  truncateFileName,
  validateAndPrepareImage
} from '../utils/imageUploadUtils';
import Prefix from '../Prefix';
import { getMaxCampaignIndex } from '../utils/commonUtils';
import {
  getCreationDate,
  getFileHash,
  getMultiSelectValue,
  getNewCreatorValue,
  getOnlyValue,
  getSelectValue,
  makeSelectOptions,
  parseBulkData
} from '../Shared';

const defaultOptions = {
  [ZM.KEY_DEFAULT_BID]: '',
  [ZM.KEY_CREATIVE_GROUPS]: [],
  [ZM.KEY_BIDS]: [],
  [ZM.KEY_SITE]: ZM.DEFAULT_SITE,
  [ZM.KEY_OS_CHOICES]: [
    ZM.IOS,
    ZM.WINDOWS_PHONE,
    ZM.WINRT,
    ZM.ANDROID,
    ZM.MACOS,
    ZM.WINDOWS,
    ZM.CHROME_OS,
    ZM.LINUX,
    ZM.ALL
  ],
  [ZM.KEY_PREFIX]: '',
  [ZM.KEY_COUNTRY]: ['us'],
  [ZM.KEY_DEVICE]: [ZM.DESKTOP, ZM.MOBILE],
  [ZM.KEY_OS_DUPLICATION_TYPE]: 'separate',
  [ZM.KEY_DEVICE_DUPLICATION_TYPE]: 'separate'
};
class ZemantaCreate extends Component {
  constructor(props) {
    super(props);

    this.suggestedBidsOB = [];
    this.defaultState = this.getDefaultState(props);
    this.state = this.defaultState;
    this.isSwag = props.programOptions.isSwag;
  }
  getDefaultState(props) {
    return {
      options: {
        ...defaultOptions,
        ...props.options,
        prefixes: [],
        prefix: ''
      },
      subAccounts: props.subAccounts || [],
      conversions: [],
      tooltips: { text: '', open: false },
      campaigns: [],
      triggerSelectorUpdate: false,
      device: [],
      destinationSubAccount: '',
      showCropWindow: false,
      browser: [],
      language: [],
      country: [],
      os: [],
      isBulk: this.props.isBulk || false,
      toggleAudience: 'include',
      togglePublisher: 'include',
      publisherInclude: null,
      publisherExclude: null,
      publishers: this.props.publishers || []
    };
  }
  createSortedList(metadata, key) {
    return Object.keys(metadata[key])
      .map(subKey => ({
        label: metadata[key][subKey],
        value: metadata[key][subKey]
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  async componentDidMount() {
    const metadata = await getZemantaMetadata();
    console.log({ metadata });
    const country = Object.keys(metadata.country)
      .map(idx => ({
        label: metadata.country[idx].toLowerCase(),
        value: metadata.country[idx]
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    const language = this.createSortedList(metadata, 'language');
    const os = this.createSortedList(metadata, 'os');
    const sourceList = this.createSortedList(metadata, 'source');
    this.setState({ country, language, os, sourceList });
    this.setDefaultBid(ZM.DEFAULT_BID);
    await this.updateUserOptions();

    if (!this.props.loadedFromSnapshot) {
      this._modifyOptions(ZM.KEY_BUDGET_AMOUNT, 30);
      this._modifyOptions(ZM.KEY_BUDGET, 'STANDARD');
      this._modifyOptions(ZM.KEY_CAMPAIGN_START_TIME, moment());
      this._modifyOptions(ZM.KEY_CAMPAIGN_STOP_TIME, moment().set('year', moment().get('year') + 10));
      if (this.state.options.prefix === 'xx') {
        this._modifyOptions(ZM.KEY_STATUS, ZM.INACTIVE);
      } else {
        this._modifyOptions(ZM.KEY_STATUS, ZM.ACTIVE);
      }
      // this.fillDomainsToConversion(ZM.DEFAULT_SITE);
    }
  }

  async updateUserOptions() {
    if (!this.props.loadedFromSnapshot) {
      const userOpts = this.props.programOptions;
      let prefix = userOpts.spm_prefix_for_zemanta ? userOpts.spm_prefix_for_zemanta : userOpts.spm_prefix_default;
      this.modifyOptions(ZM.KEY_CREATOR, getNewCreatorValue(userOpts.email, this.props.creatorsList));
      this.modifyOptions(ZM.KEY_PREFIX, prefix);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.destinationAccount !== prevProps.destinationAccount) {
      this.updateSubAccounts();
    }

    if (this.props.sourceData !== prevProps.sourceData) {
      const newOptions = this.getSourceData(
        this.state.options,
        this.props.sourceData,
        this.props.articleList,
        this.props.platform,
        this.props.creatorsList,
        this.props.isBulk
      );
      this.setState(
        {
          options: newOptions,
          isLoading: false,
          triggerSelectorUpdate: !this.state.triggerSelectorUpdate
        },
        () => {
          this.modifyBidsRows();
        }
      );
    }
    if (this.props.isBulk !== prevProps.isBulk) {
      this.setState({ isBulk: this.props.isBulk });
    }
    if (this.props.creativeFromFbLibrary) {
      this.props.creativeFbLibraryDone();
      console.log('creativeFromFbLibrary', this.props.creativeFromFbLibrary);
      this.addCreatives(this.props.creativeFromFbLibrary);
    }

    if (this.props.creative) {
      this.props.creativeDone();

      let parsedName;
      parsedName = parseCampaignName(PLATFORMS[this.props.creative.platform.toUpperCase()], this.props.creative.name);
      this.modifyOptions(ZM.KEY_COUNTRY, parsedName.country || '');
      this.modifyOptions(ZM.KEY_SITE, parsedName.site || []);

      this.modifyOptions('widgetCodeName', parsedName.widgetCodeName || '');
      this.modifyOptions('widgetLanguageCode', parsedName.widgetLanguageCode || '');
      this.modifyOptions('widgetVersion', 'shz');

      if (parsedName.platform === 'd') {
        this.modifyOptions(ZM.KEY_DEVICE, ZM.DESKTOP);
      } else if (parsedName.platform === 'a') {
        this.modifyOptions(ZM.KEY_DEVICE, [ZM.DESKTOP, ZM.MOBILE, ZM.TABLET].join(','));
      } else if (parsedName.platform === 'm') {
        this.modifyOptions(ZM.KEY_DEVICE, ZM.MOBILE);
      } else {
        if (parsedName.platform && parsedName.platform.indexOf('adr') !== -1) {
          this.modifyOptions(ZM.KEY_OS, 'Android');
          this.modifyOptions(ZM.KEY_DEVICE, ZM.MOBILE);
        }
        if (parsedName.platform && parsedName.platform.indexOf('ios') !== -1) {
          this.modifyOptions(ZM.KEY_OS, 'iOS');
          this.modifyOptions(ZM.KEY_DEVICE, ZM.MOBILE);
        }
      }

      if (typeof this.props.creative.image_url === 'string') {
        this.addCreatives([
          {
            images: [this.props.creative.image_url],
            texts: [this.props.creative.text],
            titles: [this.props.creative.title || ''],
            descriptions: [this.props.creative.text]
          }
        ]);
      } else {
        this.addCreatives([
          {
            images: this.props.creative.image_url,
            texts: [this.props.creative.text],
            titles: [this.props.creative.title || ''],
            descriptions: [this.props.creative.text]
          }
        ]);
      }
    }
  }

  getSourceData(options, sourceData, articleList, platform, creatorsList, isBulk) {
    let parsed;
    if (isBulk) {
      parsed = parseBulkData(options, sourceData, articleList, platform);
    } else {
      parsed = parseDuplicateData(options, sourceData, articleList, platform, creatorsList);
    }
    console.log({ parsed });
    return parsed;
  }
  updateSubAccounts() {
    const destinationAccount = this.props.destinationAccount;
    if (destinationAccount) {
      // getZemantaSubAccounts(destinationAccount).then(subAccounts => {
      //   this.setState({ subAccounts });
      // });
    } else {
      this.setState({ subAccounts: [] });
    }
  }

  getFullSite(domain) {
    const site = getPlatformSites(PLATFORMS.ZEMANTA.toLowerCase()).find(el => el.code === domain);
    if (site) {
      return site.name;
    }
    return undefined;
  }

  cancelCropWindow() {
    this.setState({ showCropWindow: false });
  }

  applyCropWindow(images) {
    images.map(image => {
      if (image.crops.Smartphone) image.crops.Smartphone.type = 'SMARTPHONE';

      return image;
    });
    this.addCreatives(this.state.creatives, images);
    this.setState({ showCropWindow: false });
  }
  validateOptions(key) {
    const value = this.state.options[key];
    switch (key) {
      case ZM.KEY_DEFAULT_BID:
        if (value < ZM.MIN_BID) {
          console.log('Min bid reached');
          this._modifyOptions(key, ZM.MIN_BID);
        } else if (value > ZM.MAX_BID) {
          console.log('Max bid reached');
          this._modifyOptions(key, ZM.MAX_BID);
        }
        break;
      case ZM.KEY_CONVERSION_RATE:
        if (value < ZM.MIN_CONVERSION_BID) {
          console.log('Min bid reached');
          this._modifyOptions(key, ZM.MIN_CONVERSION_BID);
        } else if (value > ZM.MAX_CONVERSION_BID) {
          console.log('Max bid reached');
          this._modifyOptions(key, ZM.MAX_CONVERSION_BID);
        }
        break;
      case ZM.KEY_BUDGET_AMOUNT:
        if (value < ZM.MIN_BUDGET) {
          console.log('Min budget reached');
          this._modifyOptions(key, ZM.MIN_BUDGET);
        }
        break;
      default:
        throw new Error('Unexpected key in validateOptions' + key);
    }
  }

  _modifyOptions(key, value) {
    const options = this.state.options;
    options[key] = value;
    this.setState({
      options: options
    });
  }

  modifyOptions(key, value) {
    if (
      key === ZM.KEY_BUDGET_AMOUNT ||
      key === ZM.KEY_DEFAULT_BID ||
      key === ZM.KEY_BID_CAP ||
      key === ZM.KEY_QUERY_PARAMETERS ||
      key === ZM.KEY_PREFIX ||
      key === 'campName' ||
      key === ZM.KEY_CONVERSION_RATE
    ) {
      // It's an "input" element
      if (value.target) {
        value = value.target.value;
      }
    }
    if (key === ZM.KEY_DEVICE && typeof value === 'string') {
      value = value.split(',');
    }

    this._modifyOptions(key, value);
    if (key === ZM.KEY_DEVICE) {
      let devices = value;
      if (typeof devices === 'string') {
        devices = devices.split(',');
      }
      let newValue = [];
      if (
        !(
          devices.includes(ZM.DESKTOP) &&
          (devices.includes(ZM.MOBILE) || devices.includes(ZM.TABLET)) &&
          this.state.options[ZM.KEY_DEVICE_DUPLICATION_TYPE] === 'group'
        )
      ) {
        devices.forEach(dev => {
          if (ZM.OS[dev]) {
            ZM.OS[dev].forEach(os => {
              if (newValue.indexOf(os) === -1) {
                newValue.push(os);
              }
            });
          }
        });
      }
      this._modifyOptions(ZM.KEY_OS_CHOICES, newValue);
      this._modifyOptions(
        ZM.KEY_OS,
        newValue.filter(el => {
          return !ZM.OS[ZM.DESKTOP].includes(el);
        })
      );
    }
    if (
      key === ZM.KEY_COUNTRY ||
      key === ZM.KEY_DEVICE ||
      key === ZM.KEY_OS ||
      key === ZM.KEY_SITE ||
      key === ZM.KEY_CONVERSION ||
      key === ZM.KEY_DEVICE_DUPLICATION_TYPE ||
      key === ZM.KEY_OS_DUPLICATION_TYPE ||
      key === GLOBAL.KEY_WIDGET_LANGUAGE_CODE
    ) {
      // Update bid rows
      this.modifyBidsRows();
    }
    if (key === ZM.KEY_DEFAULT_BID) {
      this.setDefaultBid(value);
    }
    if (key === ZM.KEY_CAMPAIGN_START_TIME_RANGE) {
      let startDate = this.state.options[ZM.KEY_CAMPAIGN_START_TIME];
      const endDate = moment(this.state.options[ZM.KEY_CAMPAIGN_START_TIME_RANGE]).add(1, 'day');
      let startDates = [];
      while (startDate <= endDate) {
        startDates.push(startDate);
        startDate = moment(startDate).add(1, 'day');
      }
      this._modifyOptions(ZM.KEY_CAMPAIGN_START_DATES, startDates);
    }
  }

  modifyBidsRows() {
    if (!this.state.options[ZM.KEY_COUNTRY] || !this.state.options[ZM.KEY_DEVICE] || !this.state.options[ZM.KEY_SITE]) {
      return;
    }
    // for some reason some of the values are arrays, some are strings
    //* in Select input - value type is string, when select all - array */
    let geos = this.state.options[ZM.KEY_COUNTRY];
    if (typeof geos === 'string') {
      geos = geos.split(',');
    }
    let devices = this.state.options[ZM.KEY_DEVICE];
    if (typeof devices === 'string') {
      devices = devices.split(',');
    }

    if (this.state.options[ZM.KEY_DEVICE_DUPLICATION_TYPE] === 'group') {
      // This devices should be treated like a group
      devices = [devices.join(',')];
    }
    let oss = this.state.options[ZM.KEY_OS] || [];
    if (typeof oss === 'string') {
      oss = oss.split(',');
    }
    if (oss.includes(ZM.ALL)) {
      oss = ZM.OS.ALL;
    }
    if (this.state.options[ZM.KEY_OS_DUPLICATION_TYPE] === 'group') {
      // This os should be treated like a group
      oss = [oss.join(',')];
    }
    let sites = this.state.options[ZM.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    let newBids = [];
    let oldBids = this.state.options[ZM.KEY_BIDS];
    geos.forEach(geo => {
      sites.forEach(site => {
        const conversions = ZM.CONVERSIONS_LIST;
        const conversionName = this.state.options[ZM.KEY_CONVERSION];
        let conversion = conversions ? conversions.find(el => el.value === conversionName) : '';
        conversion = conversion ? conversion.id : '';
        devices.forEach(dev => {
          const someOsMatch = oss.some(os => ZM.OS[dev].includes(os));
          if (someOsMatch) {
            oss.forEach(os => {
              const osMatch = ZM.OS[dev].includes(os);
              if (osMatch) {
                newBids.push({
                  country: geo,
                  device: dev,
                  site: site,
                  os: os,
                  fullSite: this.getFullSite(site),
                  bid: this.state.options[ZM.KEY_DEFAULT_BID],
                  language: this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE],
                  conversion: conversion
                });
              }
            });
          } else {
            newBids.push({
              country: geo,
              device: dev,
              site: site,
              fullSite: this.getFullSite(site),
              bid: this.state.options[ZM.KEY_DEFAULT_BID],
              language: this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE],
              conversion: conversion
            });
          }
        });
      });
    });

    newBids = newBids.map(newBid => {
      const old = oldBids.find(
        el =>
          el.country === newBid.country && el.device === newBid.device && el.os === newBid.os && el.site === newBid.site
      );
      if (old) {
        newBid.bid = old.bid;
      }
      return newBid;
    });
    this._modifyOptions(ZM.KEY_BIDS, newBids);
  }

  modifyBid(idx, e) {
    const bid = e.target.value;
    let bids = this.state.options[ZM.KEY_BIDS];
    bids[idx].bid = bid;
    this._modifyOptions(ZM.KEY_BIDS, bids);
    let warningBids = bids.filter(el => {
      return el.bid > ZM.MAX_WARN_BID;
    });
    this._modifyOptions('warnings', warningBids);
  }

  validateBid(idx) {
    let bids = this.state.options[ZM.KEY_BIDS];
    const bid = bids[idx].bid;
    if (bid < ZM.MIN_BID) {
      bids[idx].bid = ZM.MIN_BID;
      this._modifyOptions(ZM.KEY_BIDS, bids);
    } else if (bid > ZM.MAX_BID) {
      bids[idx].bid = ZM.DEFAULT_BID;
      this._modifyOptions(ZM.KEY_BIDS, bids);
    }
  }

  setDefaultBid(val) {
    let bids = this.state.options[ZM.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      if (val < ZM.MIN_BID) {
        b.bid = ZM.MIN_BID;
      } else if (val > ZM.MAX_BID) {
        b.bid = ZM.DEFAULT_BID;
      }
    });
    this._modifyOptions(ZM.KEY_BIDS, bids);
  }

  removeBid(val) {
    let bids = this.state.options[ZM.KEY_BIDS];
    bids.forEach(b => {
      b.bid = val;
      b.bid = '';
    });
    this._modifyOptions(ZM.KEY_BIDS, bids);
  }

  onAddImages(groupIdx, imageBlob, deviceType, index) {
    const images = [imageBlob];
    const errs = [];
    const warnings = [];

    const formData = new FormData();
    let targetingDevice = '';
    let basicValidatedImages = [];
    const device = deviceType;

    let options = {};
    if (this.state.options.device) {
      if (typeof this.state.options.device === 'string') {
        targetingDevice = this.state.options.device;
      } else if (this.state.options.device[0]) {
        targetingDevice = this.state.options.device[0];
      }
    }
    return Promise.all(
      images.map(file => {
        return getImageDimensions(file);
      })
    )
      .then(dimensions => {
        if (device) {
          images.forEach((file, i) => {
            const filename_truncated = truncateFileName(file.name);

            if (
              !validateAndPrepareImage(
                file,
                filename_truncated,
                dimensions[i],
                groupIdx,
                'ZM',
                warnings,
                errs,
                deviceType,
                options
              )
            ) {
              return;
            }

            formData.append(i, file, file.name);
            basicValidatedImages.push(file);
          });
        } else {
          warnings.push('Please, select the targeting device first.');
        }

        return Promise.all(
          basicValidatedImages.map(file => {
            return getFileHash(file);
          })
        );
      })
      .then(() => {
        if (displayWarnings(errs, warnings)) return;
        const scaledOptions = {
          minWidth: options.cropWidth,
          minHeight: options.cropHeight
        };
        return storeFiles(formData, scaledOptions);
      })
      .then(filenames => {
        if (filenames) {
          let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];
          if (!groups[groupIdx].images[index]) {
            groups[groupIdx].images[index] = {};
          }
          groups[groupIdx].images[index] = filenames[0];
          groups[groupIdx].device = targetingDevice;
          this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
        }
      })
      .catch(err => {
        console.log(err);
        Sentry.captureException(err);
      });
  }

  async addCreatives(xs, images) {
    let adset = '';
    const groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];

    adset = { images: [], titles: [] };
    for (const [adsetIndex, x] of xs.entries()) {
      for (const title of x.titles) {
        for (const [imageIndex] of x.images.entries()) {
          const [image] = images.filter(img => img.adsetIndex === adsetIndex && img.imageIndex === imageIndex);
          const nonEmpyCrops = Object.values(image.crops).filter(c => c !== null);
          for (const crop of nonEmpyCrops) {
            adset.images.push(crop);
            adset.titles.push(title);
          }
        }
      }
    }

    const newGroup = {
      ...adset,
      images: [],
      tooltips: []
    };

    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, [...groups, newGroup]);
    setTimeout(async () => {
      images.forEach(image => {
        for (const type in image.crops) {
          this.onAddImages(0, image.crops[type].blob, image.crops[type].type, image.imageIndex);
        }
      });
    });
  }

  onChangeImageTitle(groupIdx, imageIdx, e) {
    const title = e.target.value;
    let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];
    groups[groupIdx].titles[imageIdx] = title;
    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
  }

  onChangeImageDescription(groupIdx, imageIdx, e) {
    const description = e.target.value;
    let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];
    groups[groupIdx].descriptions[imageIdx] = description;
    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
  }

  removeImage(groupIdx, imageIdx) {
    let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.splice(imageIdx, 1);
    groups[groupIdx].titles.splice(imageIdx, 1);
    groups[groupIdx].descriptions.splice(imageIdx, 1);
    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateImage(groupIdx, imageIdx) {
    let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];
    const duplicatedImage = groups[groupIdx].images[imageIdx];
    const duplicatedText = groups[groupIdx].descriptions[imageIdx];
    const duplicateTitle = groups[groupIdx].titles[imageIdx];
    groups[groupIdx].images.push(duplicatedImage);
    groups[groupIdx].descriptions.push(duplicatedText);
    groups[groupIdx].titles.push(duplicateTitle);
    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
  }

  removeGroup(groupIdx) {
    let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];
    groups.splice(groupIdx, 1);
    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
  }

  duplicateGroup(groupIdx) {
    let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];
    // Deep copy group
    groups.push(JSON.parse(JSON.stringify(groups[groupIdx])));
    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
  }

  populateText(groupIdx, imageIdx) {
    let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS];
    groups[groupIdx].images.forEach((_, idx) => {
      groups[groupIdx].descriptions[idx] = groups[groupIdx].descriptions[imageIdx];
      groups[groupIdx].titles[idx] = groups[groupIdx].titles[imageIdx];
    });
    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
  }

  addCreativeGroup() {
    let groups = this.state.options[ZM.KEY_CREATIVE_GROUPS] || [];
    groups.push({
      images: [],
      titles: [''],
      descriptions: ['']
    });
    this.modifyOptions(ZM.KEY_CREATIVE_GROUPS, groups);
  }

  upsertSnapshot() {
    if (this.props.task === TASKS.CREATE) {
      this.props.upsertSnapshot(this.state.options);
    } else if (this.props.task === TASKS.DUPLICATE) {
      this.props.upsertSnapshot();
    }
  }

  startCreateCampaign() {
    const options = this.state.options;
    const preparedOptions = this.prepareOptions(options);
    console.log({ preparedOptions });
    if (this.props.task === TASKS.CREATE) {
      this.props.createCampaign(preparedOptions);
    } else if (this.props.task === TASKS.DUPLICATE) {
      this.props.createCampaign(preparedOptions);
    }
  }

  constructCreativeGroups(creativeGroups) {
    return creativeGroups.map(group => ({
      ad: group.images.map((image, index) => {
        let ad = {
          image,
          title: group.titles[index]
        };

        if (group.descriptions[index]) {
          ad.text = group.descriptions[index];
        }

        return ad;
      })
    }));
  }

  prepareOptions(options) {
    const payload = { campaign: [] };
    const opts = this.state.options;
    payload.campaign.push(this.prepareCreateOptions(opts));
    if (this.state.destinationAccount && this.state.destinationAccount.length > 0) {
      let result = {
        account: [
          {
            account_id: this.state.destinationAccount,
            campaign: payload.campaign
          }
        ]
      };
      console.log({ result });
      return result;
    } else {
      console.log({ payload });
      return payload;
    }
  }

  prepareBulkOptions() {
    const { options } = this.state;
    const { creativeGroups, [ZM.KEY_ORIGINAL_SOURCE_DATA]: sourceCampaigns, ...otherOptions } = options;
    let preparedCampaigns;

    if (sourceCampaigns.length === 1) {
      preparedCampaigns = sourceCampaigns.map(sourceCampaign => {
        return {
          ...otherOptions,
          ...sourceCampaign,
          creativeGroups,
          singleSourceCampaign: true
        };
      });
    } else {
      preparedCampaigns = sourceCampaigns.map(sourceCampaign => ({
        ...otherOptions,
        ...sourceCampaign
      }));
    }

    return preparedCampaigns;
  }

  prepareCreateOptions(options) {
    console.log({ options });
    const { isBulk, togglePublisher } = this.state;

    if (togglePublisher === 'include') {
      options.publisherInclude = options.publishers;
      options.publisherExclude = null;
    } else {
      options.publisherInclude = null;
      options.publisherExclude = options.publishers;
    }
    let payload = {
      budget_type: options.budget,
      budget: options.budget_amount,
      bid_type: options.bid_type,
      prefix: options.prefix,
      article: isBulk ? options.article : options.fullCodeName,
      status: options.status.toUpperCase(),
      campaign_start: options.campaign_start_time,
      campaign_stop: options.campaign_stop_time ? options.campaign_stop_time : undefined,
      conversion: options.conversion,
      creator: options.campaignCreator,
      free_text: options.freeText || '',
      note: options.campaignNote || '',
      task: this.props.task,
      language: this.state.options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE],
      source: options.source,
      environment: options.environment,
      __split: [
        this.constructCreativeGroups(options.creativeGroups),
        options.bids.map(b => {
          const bid = {
            country: b.country,
            device: b.device,
            os: b.os,
            site: b.site,
            bid: b.bid,
            conversion: [options.conversion]
          };

          return bid;
        })
      ]
    };
    if (options.publisherInclude && options.publisherInclude.length) {
      payload.publisherInclude = options.publisherInclude;
    }

    if (options.publisherExclude && options.publisherExclude.length) {
      payload.publisherExclude = options.publisherExclude;
    }
    console.log({ payload });
    return payload;
  }

  getCampaignsNumber() {
    return this.state.options[ZM.KEY_CAMPAIGN_START_DATES] && this.state.options[ZM.KEY_CAMPAIGN_START_DATES].length > 1
      ? this.state.options[ZM.KEY_CAMPAIGN_START_DATES].length *
          this.state.options[ZM.KEY_BIDS].length *
          this.state.options[ZM.KEY_CREATIVE_GROUPS].length
      : this.state.options[ZM.KEY_BIDS].length * this.state.options[ZM.KEY_CREATIVE_GROUPS].length;
  }

  getCreateButtonText() {
    const campaignsNumber = this.getCampaignsNumber();
    const displayCampaignsNumber = campaignsNumber || '';
    switch (this.props.task) {
      case TASKS.CREATE:
        return `Create ${displayCampaignsNumber} campaigns`;
      case TASKS.DUPLICATE:
        return `Duplicate campaign to ${displayCampaignsNumber} new campaigns`;
      default:
        return 'Something is wrong';
    }
  }

  getAllowedWidgetVersions() {
    let sites = this.state.options[ZM.KEY_SITE];
    if (typeof sites === 'string') {
      sites = sites.split(',');
    }
    for (const s of sites) {
      if (ZM.SITE_ALLOWED_VERSIONS[s]) {
        return ZM.SITE_ALLOWED_VERSIONS[s];
      }
    }
    return ZM.ALLOWED_VERSIONS;
  }

  renderRowName(bid) {
    const parts = [bid.country, bid.device, bid.os, bid.site];
    return parts.filter(el => el).join('-') + ' = ' + bid.bid;
  }

  validateSetup() {
    // Check that we have campaigns to create
    if (!this.state.isBulk && !this.getCampaignsNumber()) {
      const err = 'Select all required fields';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.isBulk && !this.state.options.widgetid) {
      const err = 'widget id is empty';
      console.warn(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[ZM.KEY_BID_TYPE]) {
      const err = 'Select bid type';
      console.log(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[ZM.KEY_BUDGET]) {
      const err = 'Select budget type';
      console.log(err);
      toast.error(err);
      return false;
    }

    if (!this.state.options[ZM.KEY_CONVERSION]) {
      const err = 'Select conversion';
      console.log(err);
      toast.error(err);
      return false;
    }

    const { bids } = this.state.options;
    let isValid = true;

    if (!bids || bids.length === 0) {
      const err = 'Select bid';
      console.log(err);
      toast.error(err);
      isValid = false;
    } else {
      bids.forEach(x => {
        if (!x.bid) {
          const err = `Empty bid for ${x.country}-${x.device.toLowerCase()}-${x.site}`;
          console.log(err);
          toast.error(err);
          isValid = false;
        }
      });
    }

    return isValid;
  }

  constructCampaignName(options) {
    let oldNaming = {};
    let newCampaignIndex = 1;
    if (options.sourceName) {
      oldNaming = parseCampaignName(PLATFORMS.ZEMANTA, options.sourceName);
    }
    const PLATFORM_DICT = {
      desktop: 'd',
      mobile: 'm',
      tablet: 't',
      all: 'a'
    };

    const OS_DICT = {
      android: 'adr',
      ios: 'ios',
      macos: 'mos',
      windows: 'win'
    };

    const parts = [];
    parts.push(options.prefix || oldNaming.prefix);
    let countryPart = oldNaming.country;
    if (options.country) {
      if (options.country.indexOf(',') !== -1) {
        // It's a group geo, set name to ww
        countryPart = 'ww';
      } else {
        countryPart = options.country;
      }
    }
    parts.push(countryPart);
    let platform = 'd';
    if (typeof options.device !== 'string' && options.device) {
      options.device = options.device[0] ? options.device[0] : '';
    }
    if (options.device && PLATFORM_DICT[options.device]) {
      platform = PLATFORM_DICT[options.device];
    }
    parts.push(platform || oldNaming.platform);
    if (options.site && this.props.platform === PLATFORMS.ZEMANTA) {
      if (typeof options.site === 'string') {
        options.site = options.site.split(',');
      }
      parts.push(options.site[0].toLowerCase() || oldNaming.site);
    }
    if (options.widgetCodeName && options.widgetLanguageCode) {
      parts.push(options.widgetCodeName + options.widgetLanguageCode || oldNaming.article || 'ARTICLE');
    } else {
      // We're duplicating campaign without specifying the new article/offer
      // So copy the original one
      parts.push(oldNaming.article);
    }
    if (typeof options.os === 'string') {
      options.os = options.os.split(',');
    }
    if (
      options.device &&
      !options.device.includes('desktop') &&
      options.os &&
      options.os[0] &&
      this.props.platform === PLATFORMS.ZEMANTA
    ) {
      parts.push(OS_DICT[options.os[0]]);
    }
    parts.push(getCreationDate());
    if (this.state.campaigns[0]) {
      newCampaignIndex = getMaxCampaignIndex(this.state.campaigns.map(c => c.desc)) + this.getCampaignsNumber();
    }
    parts.push('c' + newCampaignIndex);
    if (this.state.options.freeText) {
      parts.push(this.state.options.freeText);
    }
    return parts.join('-');
  }

  onChangeFreeText(e) {
    let text = e.target.value;
    this._modifyOptions('freeText', text);
    const newCampName = this.constructCampaignName(this.state.options);
    let tooltips;
    const charsLeft = ZM.MAX_CAMP_NAME_LENGTH - newCampName.length;
    tooltips = {
      text: `Chars left: ${charsLeft}`,
      open: true
    };
    const nonLetterNumber = /[^a-z0-9]+/i;
    if (text.match(nonLetterNumber)) {
      tooltips.text = 'Only letters and numbers';
      text = text.replace(nonLetterNumber, '');
      this._modifyOptions('freeText', text);
      this.setState({ tooltips });
      return;
    }
    if (charsLeft < 0) {
      text = text.slice(0, -1);
      tooltips.text = `Chars left: 0`;
    }
    this._modifyOptions('freeText', text);
    this.setState({ tooltips: tooltips });
  }

  handlePaste = event => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text').replace(',', '.');
    let value = parseFloat(pastedData);

    if (value > ZM.MAX_BID) {
      this.modifyOptions(ZM.KEY_BID_CAP, ZM.DEFAULT_BID);
    } else {
      this.modifyOptions(ZM.KEY_BID_CAP, value);
    }
  };

  onBlurFreeText() {
    let tooltips;
    tooltips = {
      open: false
    };
    this.setState({ tooltips: tooltips });
  }

  countCampaigns = campaigns => {
    return campaigns.reduce((count, campaign) => {
      if (Array.isArray(campaign.__split) && Array.isArray(campaign.__split[1])) {
        count += campaign.__split[1].length;
      }
      return count;
    }, 0);
  };

  isEmptyCreatives(options) {
    return options.campaign.every(camp => {
      const isValid = camp.__split[0]?.length && camp.__split[0].every(item => item && Object.keys(item).length > 0);
      if (!isValid) {
        console.warn(GLOBAL.ERRORS.EMPTY_CREATIVE_GROUPS + ' in campaign: ' + camp.duplicate);
        toast.error(GLOBAL.ERRORS.EMPTY_CREATIVE_GROUPS + ' in campaign: ' + camp.duplicate);
        return;
      }

      return isValid;
    });
  }

  isEmptyArticle(options) {
    return options.campaign.every(camp => {
      const isValid = camp.article;
      if (!isValid) {
        console.warn(GLOBAL.ERRORS.EMPTY_ARTICLE + ' in campaign: ' + camp.duplicate);
        toast.error(GLOBAL.ERRORS.EMPTY_ARTICLE + ' in campaign: ' + camp.duplicate);
        return;
      }

      return isValid;
    });
  }

  isEmptyCreator(options) {
    return options.campaign.every(camp => {
      const isValid = camp.creator;
      if (!isValid) {
        console.warn(GLOBAL.ERRORS.EMPTY_CREATOR + ' in campaign: ' + camp.duplicate);
        toast.error(GLOBAL.ERRORS.EMPTY_CREATOR + ' in campaign: ' + camp.duplicate);
        return;
      }

      return isValid;
    });
  }
  submit() {
    let options = this.prepareOptions(this.state.options);
    let title = '';
    let text = null;

    if (!this.validateSetup()) {
      return;
    }

    if (this.state.isBulk) {
      //just show the error message
      this.isEmptyCreatives(options);

      if (!this.isEmptyArticle(options)) {
        return;
      }
      if (!this.isEmptyCreator(options)) {
        return;
      }
    }

    const totalCount = this.state.isBulk ? this.countCampaigns(options.campaign) : this.state.options.bids.length;
    if (this.state.options.warnings && this.state.options.warnings.length > 0) {
      const warningBids = this.state.options.warnings;
      let warn = [];
      warningBids.forEach(el => {
        warn.push(this.renderRowName(el));
      });
      warn.push('end');
      title = 'You have high bids:';
      text = warn.map((el, idx) => {
        if (el === 'end') {
          return (
            <p key={idx}>
              <br />
              {'Are you sure to do this?'}
            </p>
          );
        }
        return <p key={idx}>{el}</p>;
      });
    } else {
      title = this.getCreateButtonText();
      text = this.state.isBulk
        ? `You will create ${totalCount} campaigns. Are you sure you want to do this?`
        : ` Are you sure you want to do this?`;
    }

    confirmAlert({
      title: title,
      message: text,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.startCreateCampaign()
        },
        {
          label: 'No',
          onClick: () => this.prepareOptions(this.state.options)
        }
      ]
    });
  }

  toggleAudience(value) {
    this.setState({ toggleAudience: value });
  }

  togglePublishers(value) {
    const selectedPublishers = this.state.options.publishers;
    console.log({ selectedPublishers });
    if (value === 'include') {
      this.setState({ togglePublisher: 'include' });
    } else {
      this.setState({ togglePublisher: 'exclude' });
    }
  }

  getDestinationAccountOptions() {
    return this.props.accounts
      .map(el => {
        return { label: el.desc, value: el.id };
      })
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  getDeviceNames(deviceSymbol) {
    const deviceNames = {
      d: 'DESKTOP',
      m: 'MOBILE',
      t: 'TABLET'
    };

    if (deviceSymbol) {
      return deviceNames[deviceSymbol];
    } else {
      return undefined;
    }
  }

  updateOption(key, value) {
    if (value) {
      this.modifyOptions(key, [value]);
    }
  }

  async updateDestinationAccount(acc) {
    const { accounts, siteList } = this.props;
    const selectedSubAccount = accounts.find(x => x.id === acc);
    // let publishers = await getZemantaPublishers(acc);
    // publisher = Object.keys(publishers).map(key => ({
    //   value: key,
    //   label: publishers[key]
    // }));
    // console.log(publisher);
    const autoselectItem = selectedSubAccount?.autoselect[0];
    if (autoselectItem) {
      let { site, device } = autoselectItem;
      device = this.getDeviceNames(device);
      let siteInList = siteList.some(siteObj => siteObj.value === site);
      if (siteInList) {
        this.updateOption(ZM.KEY_SITE, site);
      } else {
        this.modifyOptions(ZM.KEY_SITE, []);
      }
      if (siteList.includes(site)) {
        this.updateOption(ZM.KEY_SITE, site);
      }

      this.updateOption(ZM.KEY_DEVICE, device);
    }

    this.setState({ destinationAccount: acc, randomizeAccount: false });
  }

  render() {
    return (
      <>
        <Prefix
          value={{ label: this.state.options[ZM.KEY_PREFIX], value: this.state.options[ZM.KEY_PREFIX] }}
          onChange={x => this.modifyOptions(ZM.KEY_PREFIX, x.value)}
          options={makeSelectOptions(this.props.programOptions?.prefix)}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Destination account
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.updateDestinationAccount(x?.value)}
            options={this.getDestinationAccountOptions()}
            isClearable
            value={getSelectValue(this.getDestinationAccountOptions(), this.state.destinationAccount)}
          />
        </Grid>
        {!this.state.isBulk && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Creator
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Select
                value={getSelectValue(this.props.creatorsList, this.state.options[ZM.KEY_CREATOR])}
                onChange={x => this.modifyOptions(ZM.KEY_CREATOR, x.value)}
                options={this.props.creatorsList}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select geo
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(ZM.KEY_COUNTRY, getOnlyValue(xs))}
            options={this.props.countryList}
            value={getMultiSelectValue(this.props.countryList, this.state.options[ZM.KEY_COUNTRY])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select site
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(ZM.KEY_SITE, getOnlyValue(xs))}
            options={this.props.siteList}
            value={getMultiSelectValue(this.props.siteList, this.state.options[ZM.KEY_SITE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select device
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(ZM.KEY_DEVICE, getOnlyValue(xs))}
            options={ZM.DEVICES}
            value={getMultiSelectValue(ZM.DEVICES, this.state.options[ZM.KEY_DEVICE])}
          />
        </Grid>
        <Grid item xs={6} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.options[ZM.KEY_DEVICE_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) =>
                  this.modifyOptions(ZM.KEY_DEVICE_DUPLICATION_TYPE, value ? 'group' : 'separate')
                }
                color="primary"
              />
            }
            label={
              this.state.options[ZM.KEY_DEVICE_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Select OS
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Select
            isClearable
            isMulti
            onChange={xs => this.modifyOptions(ZM.KEY_OS, getOnlyValue(xs))}
            options={makeSelectOptions(this.state.options[ZM.KEY_OS_CHOICES])}
            value={getMultiSelectValue(
              makeSelectOptions(this.state.options[ZM.KEY_OS_CHOICES]),
              this.state.options[ZM.KEY_OS]
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} align={'center'}>
          <FormControlLabel
            control={
              <Switch
                checked={this.state.options[ZM.KEY_OS_DUPLICATION_TYPE] === 'group'}
                onChange={(e, value) => this.modifyOptions(ZM.KEY_OS_DUPLICATION_TYPE, value ? 'group' : 'separate')}
                color="primary"
              />
            }
            label={
              this.state.options[ZM.KEY_OS_DUPLICATION_TYPE] === 'group' ? (
                <Typography gutterBottom variant={'subtitle2'}>
                  Group
                </Typography>
              ) : (
                <Typography gutterBottom variant={'subtitle2'}>
                  Separate
                </Typography>
              )
            }
            labelPlacement="start"
            style={{ marginLeft: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Targeting environment
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(ZM.KEY_ENVIRONMENT, x?.value)}
            options={ZM.ENVIRONMENT_LIST}
            value={getSelectValue(ZM.ENVIRONMENT_LIST, this.state.options[ZM.KEY_ENVIRONMENT])}
          />
        </Grid>
        {!this.state.isBulk && (
          <>
            <WidgetSelector
              modify={(key, value) => this.modifyOptions(key, value)}
              articleList={this.props.articleList}
              options={this.state.options}
              allowedVersions={this.getAllowedWidgetVersions()}
              defaultVersion={ZM.DEFAULT_VERSION}
              triggerSelectorUpdate={this.state.triggerSelectorUpdate}
              programOptions={this.props.programOptions}
            />
          </>
        )}
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Conversions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(ZM.KEY_CONVERSION, getOnlyValue(xs))}
            options={ZM.CONVERSIONS_LIST}
            value={getMultiSelectValue(ZM.CONVERSIONS_LIST, this.state.options[ZM.KEY_CONVERSION])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Publishers & Placements
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(ZM.KEY_PUBLISHER, getOnlyValue(xs))}
            options={ZM.PUBLISHERS}
            value={getMultiSelectValue(ZM.PUBLISHERS, this.state.options[ZM.KEY_PUBLISHER])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Publisher settings
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} container direction={'row'} justifyContent={'center'} alignItems={'center'}>
          <Grid item sm={5} spacing={1} container direction={'row'} style={{ marginTop: '5px' }}>
            <input
              style={{ padding: '7px', marginBottom: '5px' }}
              type="checkbox"
              onChange={() => this.togglePublishers('include')}
              checked={this.state.togglePublisher === 'include'}
            />
            <Typography gutterBottom variant={'subtitle2'}>
              Include
            </Typography>
            <Tooltip title={`The campaign will include the audience you target`} placement="right">
              <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                ?
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item sm={5} spacing={1} container direction={'row'} style={{ marginTop: '5px' }}>
            <input
              style={{ padding: '7px', marginBottom: '5px' }}
              type="checkbox"
              onChange={() => this.togglePublishers('exclude')}
              checked={this.state.togglePublisher === 'exclude'}
            />
            <Typography gutterBottom variant={'subtitle2'}>
              Exclude
            </Typography>
            <Tooltip title={`The campaign will exclude the audience you target`} placement="right">
              <Typography gutterBottom variant="subtitle2" color={'primary'} sx={{ marginLeft: '10px' }}>
                ?
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Media source
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            isMulti
            onChange={xs => this.modifyOptions(ZM.KEY_SOURCE, getOnlyValue(xs))}
            options={this.state.sourceList}
            value={getMultiSelectValue(this.state.sourceList, this.state.options[ZM.KEY_SOURCE])}
          />
        </Grid>
        <ZemantaBids
          bids={this.state.options[ZM.KEY_BIDS]}
          modifyBid={(idx, e) => this.modifyBid(idx, e)}
          validateBid={idx => this.validateBid(idx)}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Budget type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(ZM.KEY_BUDGET, x?.value)}
            options={ZM.SPENDING_MODEL_LIST}
            value={getSelectValue(ZM.SPENDING_MODEL_LIST, this.state.options[ZM.KEY_BUDGET])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Budget
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <input
            type="number"
            name={ZM.KEY_BUDGET_AMOUNT}
            min={ZM.MIN_BUDGET}
            value={this.state.options[ZM.KEY_BUDGET_AMOUNT] || 0}
            onChange={value => this.modifyOptions(ZM.KEY_BUDGET_AMOUNT, value)}
            onBlur={value => this.validateOptions(ZM.KEY_BUDGET_AMOUNT, value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Bid Type
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(ZM.KEY_BID_TYPE, x?.value)}
            options={ZM.BID_TYPE_LIST}
            value={getSelectValue(ZM.BID_TYPE_LIST, this.state.options[ZM.KEY_BID_TYPE])}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign start date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            startDate={this.state.options[ZM.KEY_CAMPAIGN_START_TIME]}
            endDate={this.state.options[ZM.KEY_CAMPAIGN_START_TIME_RANGE]}
            selected={
              this.state.options[ZM.KEY_CAMPAIGN_START_TIME]
                ? new Date(this.state.options[ZM.KEY_CAMPAIGN_START_TIME])
                : ''
            }
            onChange={value => this.modifyOptions(ZM.KEY_CAMPAIGN_START_TIME, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Campaign end date
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DatePicker
            startDate={this.state.options[ZM.KEY_CAMPAIGN_START_TIME]}
            endDate={this.state.options[ZM.KEY_CAMPAIGN_START_TIME_RANGE]}
            selected={
              this.state.options[ZM.KEY_CAMPAIGN_START_TIME_RANGE]
                ? new Date(this.state.options[ZM.KEY_CAMPAIGN_START_TIME_RANGE])
                : ''
            }
            onChange={value => this.modifyOptions(ZM.KEY_CAMPAIGN_START_TIME_RANGE, value)}
            showTimeSelect
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Status
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Select
            onChange={x => this.modifyOptions(ZM.KEY_STATUS, x?.value)}
            options={ZM.STATUS}
            value={getSelectValue(ZM.STATUS, this.state.options[ZM.KEY_STATUS])}
          />
        </Grid>
        <CampaignsNote
          value={this.state.options[GLOBAL.KEY_CAMPAIGN_NOTE]}
          onChange={e => {
            this._modifyOptions(GLOBAL.KEY_CAMPAIGN_NOTE, e.target.value);
          }}
        />
        <Grid item xs={12} sm={4}>
          <Typography gutterBottom variant={'subtitle2'}>
            Free text
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Tooltip key={'tooltip'} title={this.state.tooltips ? this.state.tooltips.text : ''}>
            <textarea
              key={'freeText'}
              value={this.state.options.freeText}
              onChange={e => this.onChangeFreeText(e)}
              onBlur={() => this.onBlurFreeText()}
              maxLength={40}
              rows="1"
              style={{
                resize: 'none'
              }}
            />
          </Tooltip>
        </Grid>
        {!this.state.isBulk || (this.state.isBulk && this.state.options.original_source_data?.length === 1) ? (
          <>
            <Grid item xs={12} sm={4}>
              <Typography gutterBottom variant={'subtitle2'}>
                Creative Groups:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} align="right"></Grid>
            <Grid item xs={6} sm={4} align="right">
              <Button fullWidth variant="contained" color="primary" onClick={() => this.addCreativeGroup()}>
                Add group
              </Button>
            </Grid>
            <ZemantaCreativeGroupBuilder
              groups={this.state.options[ZM.KEY_CREATIVE_GROUPS]}
              onAddImages={(groupIdx, imageBlob, deviceType, index) =>
                this.onAddImages(groupIdx, imageBlob, deviceType, index)
              }
              onChangeImageTitle={(groupIdx, imageIdx, e) => this.onChangeImageTitle(groupIdx, imageIdx, e)}
              onChangeImageDescription={(groupIdx, imageIdx, e) => this.onChangeImageDescription(groupIdx, imageIdx, e)}
              removeImage={(groupIdx, imageIdx) => this.removeImage(groupIdx, imageIdx)}
              duplicateImage={(groupIdx, imageIdx) => this.duplicateImage(groupIdx, imageIdx)}
              removeGroup={idx => this.removeGroup(idx)}
              duplicateGroup={idx => this.duplicateGroup(idx)}
              populateText={(groupIdx, imageIdx) => this.populateText(groupIdx, imageIdx)}
            />
          </>
        ) : null}
        <Grid item xs={12} sm={4} align="right"></Grid>
        <Grid item xs={6} sm={4} align="right">
          <Button variant="outlined" fullWidth color="primary" onClick={() => this.upsertSnapshot()}>
            {this.props.loadedFromSnapshot ? 'Update' : 'Save'} snapshot
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} align="right">
          <Button variant="contained" fullWidth color="primary" onClick={() => this.submit()}>
            {this.getCreateButtonText()}
          </Button>
        </Grid>
      </>
    );
  }
}

function formCreativeGroup(adsData) {
  const group = {
    images: [],
    descriptions: [],
    titles: []
  };
  if (Array.isArray(adsData)) {
    adsData.forEach(ad => {
      group.images.push(ad.image);
      group.descriptions.push(ad.text);
      group.titles.push(ad.title);
    });
  } else {
    group.images.push(adsData.image);
    group.descriptions.push(adsData.text);
    group.titles.push(adsData.title);
  }
  return group;
}

function parseDuplicateData(options, sourceData) {
  console.log({ sourceData });
  if (sourceData && sourceData?.error?.message) {
    toast.error(sourceData.error.message);
    //{
    //     "sourceData": {
    //         "platform": "zm",
    //         "name": "52033059",
    //         "all": 1,
    //         "error": {
    //             "message": "not found",
    //             "reqid": "ws9:/ui:daria@shinez.io:32:_ws/def:3:3:zm/campaign/load:3"
    //         },
    //         "limit": 1,
    //         "ts": 1730368263737,
    //         "finished": 1730368263923
    //     }
    // }
    return options;
  }
  //{
  //     "sourceData": {
  //         "account": {
  //             "account_id": "80398",
  //             "campaign": {
  //                 "prefix": "nh",
  //                 "site": "mdm",
  //                 "article": "modelenshz",
  //                 "status": "PAUSED",
  //                 "budget": "10.0000",
  //                 "budget_type": "STANDARD",
  //                 "bid_type": "MAXCPC",
  //                 "bid": "0.0300",
  //                 "conversion": [
  //                     "EPC_0.13"
  //                 ],
  //                 "device": "MOBILE",
  //                 "country": [
  //                     "US"
  //                 ],
  //                 "language": "ENGLISH",
  //                 "browser": [],
  //                 "os": [
  //                     "IOS"
  //                 ],
  //                 "note": "hi",
  //                 "tags": "test1",
  //                 "creator": "test",
  //                 "ad": [
  //                     {
  //                         "image": "https://zem.outbrainimg.com/p/srv/sha/7c/61/40/23d018e8704e6398a31ad5711203d6eb92.jpg?w=600&h=886&fit=crop&crop=center",
  //                         "title": "90's Models, Now & Then",
  //                         "text": "Our Favorite Supermodels of the '90s: Where Are They Now"
  //                     }
  //                 ],
  //                 "_name": "nh-us-m-mdm-modelen-ios-120924-c19",
  //                 "_id": "52388046"
  //             }
  //         },
  //         "ts": 1726650806234,
  //         "finished": 1726650810392
  //     }
  // }
  let data = sourceData.account.campaign;
  options[ZM.KEY_CREATIVE_GROUPS] = data.ad.map(formCreativeGroup);
  options[ZM.KEY_BIDS] = [];
  const parsedName = parseCampaignName(PLATFORMS.ZEMANTA, data._name);
  options[GLOBAL.KEY_WIDGET_CODE_NAME] = parsedName.widgetCodeName;
  options[GLOBAL.KEY_WIDGET_LANGUAGE_CODE] = parsedName.widgetLanguageCode;
  options[GLOBAL.KEY_CAMPAIGN_NOTE] = data?.note;
  options[ZM.KEY_CREATOR] = data.creator;
  options[ZM.KEY_SITE] = [parsedName.site];
  options[ZM.KEY_COUNTRY] = [parsedName.country];
  options[ZM.KEY_DEVICE] = [];

  const title = 'Incorrect source campaign name.';
  const text = `Unknown device "${parsedName.device}"`;
  switch (parsedName.device) {
    case 'd':
      options[ZM.KEY_DEVICE].push(ZM.DESKTOP);
      break;
    case 'm':
      options[ZM.KEY_DEVICE].push(ZM.MOBILE);
      break;
    case 't':
      options[ZM.KEY_DEVICE].push(ZM.TABLET);
      break;
    case 'a':
      options[ZM.KEY_DEVICE].push(ZM.ALL);
      break;
    default:
      options[ZM.KEY_DEVICE].push(null);

      confirmAlert({
        title: title,
        message: text,
        buttons: [
          {
            label: 'OK'
          }
        ]
      });
  }
  options[ZM.KEY_OS_CHOICES] = ZM.OS[data.device];
  options[ZM.KEY_BUDGET] = data.budget_type;
  options[ZM.KEY_BUDGET_AMOUNT] = data.budget / 10;
  options[ZM.KEY_BID_TYPE] = data.bid_type;
  options[ZM.KEY_OS] = ZM.OS.ALL.filter(os => data.os?.some(inputOs => inputOs.toLowerCase() === os.toLowerCase()));
  if (options.prefix !== 'xx') {
    options[ZM.KEY_STATUS] = data.enabled ? ZM.ACTIVE : ZM.INACTIVE;
  }
  return options;
}

export { ZemantaCreate };
